import { InjectionToken } from '@angular/core';

export interface ApplicationConfig {
	uri: Uri;
	analytics: AnalyticsConfig;
	auth: AuthConfig;
	qualityGateRules: QualityGateConfigRule[];
	showDataProgress: boolean;
	slackIntegrationURL: string;
	messages?: Messages;
	userPilot?: UserPilotConfig;
	manualTests?: ManualTests;
	browserExtension: BrowserExtension;
	customTabs?: CustomTabConfig[];
}

export interface Uri {
	apiBaseUrl: string;
}

export interface AnalyticsConfig {
	debug?: boolean;
}

export interface AuthConfig {
	cognitoPolicy: CognitoPolicyField[];
}

export interface CognitoPolicyField {
	key: string;
	rule: string;
	regex: string;
}

export interface QualityGateConfigRuleOption {
	value: string;
	display: string;
	hasParameter?: boolean;
	minimum?: number;
	maximum?: number;
	parameterType?: string;
}

export interface QualityGateConfigRule {
	name: string;
	display: string;
	options: QualityGateConfigRuleOption[];
}

export interface Messages {
	sso: string;
	support: {
		title: string;
		content: string;
	};
}

export interface UserPilotConfig {
	token: string;
	experiences: {
		[key: string]: string;
	};
}

export interface ManualTests {
	testStage: {
		names: string[];
	};
}

export interface BrowserExtension {
	id: string;
	resourceToCheck: string;
}

export interface CustomTabConfig {
	url: string;
	name: string;
	enabled?: boolean;
	order?: number;
}

export const APP_CONFIG = new InjectionToken<ApplicationConfig>('APP_CONFIG');

export interface ApplicationConfigProviderOptions {
	config: ApplicationConfig;
}

export const initialApplicationConfig: ApplicationConfig = {
	uri: {
		apiBaseUrl: '/api/',
	},
	analytics: {
		debug: false,
	},
	auth: {
		cognitoPolicy: [],
	},
	qualityGateRules: [],
	showDataProgress: false,
	slackIntegrationURL: '',
	browserExtension: {
		id: '',
		resourceToCheck: '',
	},
	customTabs: null,
	manualTests: null,
	messages: null,
	userPilot: null,
};
